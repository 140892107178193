import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import withAppContext from '../../withAppContext';

import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import { Button as ButtonV2 } from '../home/v2/styledComponents';
import rightArrow from '../../assets/chevron right.png';
import leftArrow from '../../assets/chevron left.png';

const Button = styled(ButtonV2)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || !props.back ? colors.navy : 'transparent'};
  color: ${(props) => props.textColor || !props.back ? colors.white : colors.navy};
  ${(props) => !props.isAvailable && `
    background-color: ${colors.lightGrey3};
    color: ${colors.white};
    pointer-events: none;
    cursor: unset;
    border-color: ${colors.lightGrey3};`} 
  text-transform: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  height: 54px;
  padding: 1px 45px;
  ${(props) => props.noZoom
  && `&:hover {
    transform: none;
  }`} 

  @media (max-width: ${mobileThresholdPixels}) {
    height: 45px;
    font-size: 13px;
    min-width: 210px;
    padding: 10px;
    margin: 10px 37px 0px;
    order: 0;
    ${(props) => (props.mobileFirst || !props.back) && 'order: -1; margin-top: 0px;'}
  }
`;

const Logo = styled.img`
  position: absolute;
  font-weight: 500;
  width: auto;
  ${(props) => (props.back ? 'left: 14px;' : 'right: 14px;')}
  margin: 0px;
`;

const LogoButton = ({
  children, back, backgroundColor, textColor, context: { isMobile }, logo, neverHideLogo,
  mobileFirst, isAvailable, onClick, noZoom, hideLogo,
}) => (
  <Button
    back={back}
    noZoom={noZoom}
    backgroundColor={backgroundColor}
    textColor={textColor}
    mobileFirst={mobileFirst}
    isAvailable={isAvailable}
    onClick={onClick}
  >
    {!hideLogo && back && (neverHideLogo || !isMobile)
      && <Logo back src={logo || leftArrow} />}
    {children}
    {!hideLogo && !back && (neverHideLogo || !isMobile)
      && <Logo src={logo || rightArrow} />}
  </Button>
);

LogoButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  back: PropTypes.bool,
  noZoom: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  logo: PropTypes.string,
  neverHideLogo: PropTypes.bool,
  mobileFirst: PropTypes.bool,
  isAvailable: PropTypes.bool,
  hideLogo: PropTypes.bool,
};

LogoButton.defaultProps = {
  onClick() {},
  back: false,
  noZoom: false,
  backgroundColor: undefined,
  textColor: undefined,
  logo: null,
  neverHideLogo: false,
  mobileFirst: false,
  isAvailable: true,
  hideLogo: false,
};

export default withAppContext(LogoButton);
