import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import logoMastercard from '../../assets/order/mastercard.png';
import logoVisa from '../../assets/order/visa.png';
import radio from '../../assets/order/Radio.png';
import radioSelected from '../../assets/order/RadioSelected.png';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';

const RadioButtonContainer = styled.div`
  cursor: pointer;
  display:  flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid ${colors.lightGrey2};
  padding: 20px 0px;
  ${(props) => props.marginBottom && 'margin-bottom: 32px;'}
  ${(props) => props.opaque && 'opacity: 0.2;'}
  cursor: pointer;
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px 0px;
    ${(props) => props.marginBottom && 'margin-bottom: 22px;'}
  }
`;

const RadioButtonLabel = styled.label`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  line-height: 14px;
  cursor: pointer;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const CardNumberAndTypeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RadioImg = styled.img`
  margin-right: 31px;
  height: 30px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 11px;
    height: 25px;
  }
`;

const ImgCard = styled.img`
  margin-right: 48px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 41px;
    margin-right: 8px;
  }
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${colors.navy};
  margin: 0px 0.5px;
  ${(props) => props.marginRight && 'margin-right: 5px;'}
`;

const CreditCardsSelector = ({
  cards, isAddNewCardSelected, selectedCardIndex, selectCard,
}) => (
  <div>
    {cards.map((card, index) => (
      <RadioButtonContainer
        key={card.id}
        opaque={isAddNewCardSelected}
        marginBottom={isAddNewCardSelected && index === cards.length - 1}
        onClick={() => selectCard(index)}
      >
        <RadioImg
          src={index === selectedCardIndex ? radioSelected : radio}
          alt={`carte n°${index}`}
          id={card.id}
        />
        <RadioButtonLabel htmlFor={card.id}>
          <CardNumberAndTypeContainer>
            <ImgCard
              src={card.cardProvider === 'MASTERCARD' ? logoMastercard : logoVisa}
              alt={`${card.cardProvider} logo`}
            />
            <Dot />
            <Dot />
            <Dot />
            <Dot marginRight />
            {card.alias.slice(-4)}
          </CardNumberAndTypeContainer>
          {`${card.expirationDate.slice(0, 2)}/20${card.expirationDate.slice(-2)}`}
        </RadioButtonLabel>
      </RadioButtonContainer>
    ))}
  </div>
);

CreditCardsSelector.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCardIndex: PropTypes.number,
  isAddNewCardSelected: PropTypes.bool,
  selectCard: PropTypes.func,
};

CreditCardsSelector.defaultProps = {
  selectedCardIndex: undefined,
  isAddNewCardSelected: false,
  selectCard() {},
};

export default CreditCardsSelector;
