import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { metadata } from '../../services/pricing';
import { pushToLayer } from '../../services/googleTagManager';
import routesMap from '../../Routes';
import { registerCard, isCardTypeValid, isCardNumberValid } from '../../services/mangopay';

import withOrderContext from '../../withOrderContext';
import withCustomerContext from '../../withCustomerContext';
import {
  StyledTitle2 as Title2V3, mobileThresholdPixels, colors, Button as ButtonV3,
} from '../home/v3/styledComponents';
import OrderSummaryMasks from './masks/OrderSummaryMasks';
import callApi from '../../services/api';
import { checkZipcodeErrorType, getLocality } from '../../services/zipcode';
import { isEmailValid, isPhoneValid } from '../../services/checkingFormat';
import LogoButton from './LogoButton';
import tick from '../../assets/tick.png';
import ContactInfos from './ContactInfos';
import PopUpWaitTime from './PopUpWaitTime';
import CreditCard from './CreditCard';
import CreditCardsSelector from './CreditCardsSelector';
import { inputs, inputsAlreadyCustomer } from './meetingInfosData';
import logoMangopay from '../../assets/Mangopay2.png';

const ColoredSpan = styled.span`
  color: ${colors.red};
`;

const getZipcodeErrorLabel = (message) => (
  <ColoredSpan error>{message}</ColoredSpan>
);

const Title2 = styled(Title2V3)`
  margin-bottom: 81px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 30px;
    margin-bottom: 6px;
  }
`;

const MeetingInfosContainer = styled.div`
  width: 100%;
`;

const ComponentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.marginBottom && 'margin-bottom: 100px;'}
  ${(props) => props.marginTop && 'margin-top: 60px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
    ${(props) => props.marginBottom && 'margin-bottom: 60px;'}
    margin-top: 0px;
  }
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const GuaranteesContainer = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 15px;
  align-items: baseline;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
    margin-bottom: 15px;
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  height: 17px;
  width: auto;
  margin-right: ${(props) => props.marginRight}px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: ${(props) => props.marginRight - 6}px;
  }
`;

const Title = styled.div`
  font-family: Libre Baskerville;
  font-weight: 500;
  line-height: 30px;
  font-size: 24px;
  margin-top: 44px;
  margin-bottom: 22px;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 20px;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
`;

const Button = styled(ButtonV3)`
  width: 245px;
  padding: 3px 15px 0px;
  margin-top: 59px;
  font-size: 18px;
  ${(props) => props.marginRight && 'margin-right: 45px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 13px;
    width: 187px;
    min-width: unset;
    margin: 25px auto 0px;
    ${(props) => props.mobileSmallMarginTop && 'margin-top: 10px;'}
  }
`;

class MaskMeetingInfos extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      isAlreadyCustomer: false,
      formInputs: { ...inputs },
      formInputsAlreadyCustomer: { ...inputsAlreadyCustomer },
      popUpLocalityIsOpen: false,
      popUpWaitTimeIsOpen: false,
      selectedAddress: {},
      addingNewAddress: false,
      addressList: [],
      isSubscribingToNewsletter: false,
    };
  }

  componentDidMount() {
    const { customerContext: { customer } } = this.props;
    if (customer !== undefined) {
      this.setCustomer();
    }
  }

  componentDidUpdate(prevProps) {
    const { customerContext: { customer } } = this.props;
    if (!prevProps.customerContext.customer && !!customer) {
      this.setCustomer();
    }
  }

  onSelectAddress = (address) => {
    this.setState({
      selectedAddress: { ...address },
    });
  }

  setCustomer = () => {
    const { customerContext: { customer } } = this.props;
    this.setIsAlreadyCustomer(true);
    this.setIsLoggedIn(true);
    this.displayAddressesList(true);

    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    newFormInputs.firstname.value = customer.firstname;
    newFormInputs.firstname.error = undefined;
    newFormInputs.firstname.hasError = false;
    newFormInputs.lastname.value = customer.lastname;
    newFormInputs.lastname.error = undefined;
    newFormInputs.lastname.hasError = false;
    newFormInputs.email.value = customer.email;
    newFormInputs.email.error = undefined;
    newFormInputs.email.hasError = false;
    newFormInputs.phone.value = customer.phone;
    newFormInputs.phone.error = undefined;
    newFormInputs.phone.hasError = false;
    this.setFormInputs(formInputs);
  }

  setFormInputs = (formInputs) => {
    this.setState({ formInputs });
  }

  setIsAlreadyCustomer = (isAlreadyCustomer) => {
    this.setState({ isAlreadyCustomer });
  }

  setIsLoggedIn = (isLoggedIn) => {
    this.setState({ isLoggedIn });
  }

  setFormInputValue = (event, listName) => {
    const { state } = this;
    const { target: { name, value } } = event;
    const newFormInputs = { ...state[listName] };
    if (name === 'zipcode') {
      newFormInputs.locality.value = getLocality(value) ?? '';
    }
    newFormInputs[name].value = value;
    if (listName === 'formInputsAlreadyCustomer' || name !== 'email') {
      this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
    } else {
      const isValid = isEmailValid(value);
      if (isValid) {
        callApi(`public/checkEmail/${value}`, 'get')
          .then(() => {
            newFormInputs.email.more.isActive = true;
            this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
          })
          .catch(() => {
            newFormInputs.email.more.isActive = false;
            this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
          });
      } else {
        newFormInputs.email.more.isActive = false;
        this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
      }
    }
  }

  setIsAlreadyCustomerWhenEmailChecked = () => {
    const { formInputsAlreadyCustomer: formInputsAlreadyCustomerState, formInputs } = this.state;
    const formInputsAlreadyCustomer = { ...formInputsAlreadyCustomerState };
    formInputsAlreadyCustomer.email.value = formInputs.email.value;
    formInputsAlreadyCustomer.email.error = false;
    formInputsAlreadyCustomer.errorEmail.isActive = false;
    this.setState({
      isAlreadyCustomer: true,
      formInputsAlreadyCustomer: { ...formInputsAlreadyCustomer },
    });
  }

  setPopUpIsOpen = (popUpLocalityIsOpen) => {
    this.setState({ popUpLocalityIsOpen });
  }

  setCustomerAndOrderId = (customer, orderId) => {
    const {
      customerContext: { setCustomer },
      orderContext: { setOrderId },
    } = this.props;
    setCustomer(customer);
    setOrderId(orderId);
  }

  getSelectedCardToken = () => {
    const { selectedCardIndex, isAddingNewCard } = this.state;
    if (isAddingNewCard || typeof selectedCardIndex !== 'number') return null;
    const { customerContext: { customer: { cards } } } = this.props;
    return cards[selectedCardIndex]?.id;
  }

  setCreditCardInputValue = (input, value) => {
    if (input === 'cardNumber') this.validateCardNumber(value);
    this.setState({ [input]: value });
  }

  displayAddressesList = (mustSetAddress = false) => {
    const { customerContext: { customer } } = this.props;
    const addressesList = [
      { ...customer.address, _id: 'initialAddress' },
      ...customer.addresses,
    ].filter((address) => address.street);
    if (mustSetAddress && addressesList.length > 0) {
      const { formInputs: formInputsState } = this.state;
      const formInputs = { ...formInputsState };
      formInputs.street.value = addressesList[0].street;
      formInputs.street.error = undefined;
      formInputs.street.hasError = false;
      formInputs.zipcode.value = addressesList[0].zipcode;
      formInputs.zipcode.error = undefined;
      formInputs.zipcode.hasError = false;
      formInputs.errorZipcode.isActive = false;
      formInputs.locality.value = addressesList[0].locality;
      formInputs.locality.error = undefined;
      formInputs.locality.hasError = false;
      formInputs.comment.value = addressesList[0].comment;
      formInputs.comment.error = undefined;
      this.setState({ formInputs: { ...formInputs } });
    }
    this.setState({
      addressesList,
      selectedAddress: addressesList[0],
      addingNewAddress: !(addressesList && addressesList.length > 1),
    });
    return addressesList;
  }

  addAddress = () => {
    this.setIsAlreadyCustomer(true);
    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    newFormInputs.street.value = '';
    newFormInputs.comment.value = '';
    newFormInputs.zipcode.value = '';
    newFormInputs.locality.value = '';
    newFormInputs.street.error = undefined;
    newFormInputs.zipcode.error = undefined;
    newFormInputs.locality.error = undefined;
    newFormInputs.street.hasError = undefined;
    newFormInputs.zipcode.hasError = undefined;
    newFormInputs.locality.hasError = undefined;
    this.setState({
      addingNewAddress: true,
      formInputs: { ...newFormInputs },
    });
  }

  login = ({ accessToken } = {}) => {
    const { formInputsAlreadyCustomer: { email, password } } = this.state;
    const request = accessToken
      ? { fbAccessToken: accessToken }
      : { email: email.value, password: password.value };
    callApi('login', 'post', request)
      .then(({ customer }) => {
        const {
          _id, firstname, lastname, phone, discountVouchers, email: customerEmail,
        } = customer;
        const address = customer.address && customer.address.street ? customer.address : customer.addresses[0];
        const {
          street, comment, zipcode, locality,
        } = address;
        pushToLayer({ event: 'Login', is_logged_in: true, user_id: _id });
        const { formInputs } = this.state;
        const newFormInputs = { ...formInputs };
        newFormInputs.firstname.value = firstname ?? '';
        newFormInputs.lastname.value = lastname ?? '';
        newFormInputs.street.value = street ?? '';
        newFormInputs.comment.value = comment ?? '';
        newFormInputs.zipcode.value = zipcode ?? '';
        newFormInputs.locality.value = locality ?? '';
        newFormInputs.email.value = customerEmail;
        newFormInputs.phone.value = phone;
        const {
          customerContext: { setCustomer, setCustomerDiscountVouchers },
        } = this.props;
        setCustomer(customer);
        setCustomerDiscountVouchers(discountVouchers);
        this.displayAddressesList();
        this.setState({
          formInputs: { ...newFormInputs },
          isLoggedIn: true,
        }, () => Object.keys(newFormInputs).forEach((formInput) => this.checkFormInputValue(formInput, 'formInputs')));
      })
      .catch(() => {
        const { formInputsAlreadyCustomer } = this.state;
        const newFormInputs = { ...formInputsAlreadyCustomer };
        newFormInputs.errorConnection.isActive = true;
        newFormInputs.email.error = undefined;
        this.setState({
          formInputsAlreadyCustomer: { ...newFormInputs },
          isLoggedIn: false,
        });
      });
  }

  createCustomerRequest = () => {
    const {
      formInputs, selectedAddress, isAlreadyCustomer, addingNewAddress, isSubscribingToNewsletter,
    } = this.state;
    const { customerContext: { customer } } = this.props;
    const {
      firstname, lastname, street, comment, zipcode, locality, phone, email,
    } = formInputs;
    const addressFromForm = {
      street: street.value,
      comment: comment.value,
      zipcode: zipcode.value,
      locality: locality.value,
    };
    if (!isAlreadyCustomer || addingNewAddress) {
      const { addresses = [] } = customer ?? {};
      const address = addresses.find((addressIt) => addressIt.street === addressFromForm.street
        && addressIt.zipcode === addressFromForm.zipcode
        && addressIt.locality === addressFromForm.locality
        && addressIt.comment === addressFromForm.comment) || addressFromForm;
      return {
        firstname: firstname.value,
        lastname: lastname.value,
        phone: phone.value,
        email: email.value,
        address,
        hasSubscribedToNewsletter: isSubscribingToNewsletter,
      };
    } return {
      firstname: customer.firstname,
      lastname: customer.lastname,
      phone: customer.phone,
      email: customer.email,
      address: selectedAddress,
    };
  }

  createOrderRequest = (customer) => {
    const { orderContext: { masks, deliveryFeeMasks } } = this.props;

    const items = masks?.map((mask) => ({ ...mask, price: mask.prices.DEFAULT }));
    return {
      rdv1: { begin: new Date(), end: new Date() },
      fabrics: ['mask'],
      minOrderAmount: metadata.minOrderAmount,
      deliveryFee: deliveryFeeMasks,
      pricingVersion: metadata.version,
      clothes: [{
        slug: 'mask',
        name: 'Masque',
        items,
      }],
      address: customer && customer.address && customer.address._id, // eslint-disable-line no-underscore-dangle
      isMask: true,
      cardToken: this.getSelectedCardToken(),
    };
  }

  createRequest = () => {
    const { customerContext: { customer: customerProps } } = this.props;
    const customer = this.createCustomerRequest();
    const order = this.createOrderRequest(customer);
    const { addingNewAddress, isSubscribingToNewsletter } = this.state;
    const customerHasSubscribedToNewsletter = customerProps && customerProps.hasSubscribedToNewsletter;
    const hasSubscribedToNewsletter = customerHasSubscribedToNewsletter || isSubscribingToNewsletter;

    if (addingNewAddress && !order.address) {
      return { address: customer.address, order, hasSubscribedToNewsletter };
    }
    return { customer, order, hasSubscribedToNewsletter };
  }

  checkFormInputValue = (name, listName, mustDefineError = true) => {
    const { state } = this;
    const { isLoggedIn } = state;
    const newFormInputs = { ...state[listName] };
    if (name === 'zipcode') {
      const errorCode = checkZipcodeErrorType(newFormInputs.zipcode.value);
      if (mustDefineError) {
        newFormInputs.errorZipcode.isActive = errorCode === 1;
        newFormInputs.zipcode.error = errorCode === 1;
      }
      newFormInputs.zipcode.hasError = errorCode === 1;
      if (errorCode === 1) {
        newFormInputs.errorZipcode.label = getZipcodeErrorLabel("Merci d'entrer un code postal à 5 chiffres");
      } else {
        newFormInputs.errorZipcode.label = null;
      }
      if (newFormInputs.locality.error === undefined && newFormInputs.locality.value !== '') {
        newFormInputs.locality.error = false;
        newFormInputs.locality.hasError = false;
      } else if (newFormInputs.locality.error !== undefined) {
        if (mustDefineError) {
          newFormInputs.locality.error = newFormInputs.locality.value === '';
        }
        newFormInputs.locality.hasError = newFormInputs.locality.value === '';
      }
    } else if (name === 'email') {
      const isValid = isEmailValid(newFormInputs.email.value);
      if (mustDefineError) {
        newFormInputs.errorEmail.isActive = !isValid;
        newFormInputs.email.error = !isValid;
      }
      newFormInputs.email.hasError = !isValid;
    } else if (name === 'phone' && !isLoggedIn) {
      const isValid = isPhoneValid(newFormInputs.phone.value);
      if (mustDefineError) {
        newFormInputs.errorPhone.isActive = !isValid;
        newFormInputs.phone.error = !isValid;
      }
      newFormInputs.phone.hasError = !isValid;
    } else if (newFormInputs[name].isRequired) {
      if (mustDefineError) {
        newFormInputs[name].error = newFormInputs[name].value === '';
      }
      newFormInputs[name].hasError = newFormInputs[name].value === '';
    } else {
      newFormInputs[name].error = newFormInputs[name].value === '' ? undefined : false;
      newFormInputs[name].hasError = false;
    }
    this.setState({ [listName]: { ...newFormInputs } }, () => this.checkIsDone());
  }

  checkIsCardDone = () => {
    const {
      expiryMonth, expiryYear, cvv, cardNumber,
    } = this.state;
    return this.getSelectedCardToken() || (expiryMonth && expiryYear && cvv && cardNumber);
  }

  checkIsDone = () => {
    const { formInputs } = this.state;
    const isFormDone = Object.values(formInputs)
      .reduce((acc, { isRequired, hasError }) => acc && (!isRequired || hasError === false), true);
    const isCardDone = this.checkIsCardDone();
    return isFormDone && isCardDone;
  }

  updateDeliveryFees = (deliveryFee) => {
    const { orderContext: { updateDeliveryFee } } = this.props;
    updateDeliveryFee(deliveryFee);
  }

  validateCardNumber = (cardNumber) => {
    const strippedCardNumber = cardNumber.replace(/ /g, '');
    if (!isCardTypeValid(strippedCardNumber)) {
      this.setState({
        cardError: 'Les cartes American Express (Amex) ne sont pas supportées.',
      });
    } else if (
      strippedCardNumber
      && strippedCardNumber.length > 15
      && !isCardNumberValid(strippedCardNumber)
    ) {
      this.setState({
        cardError: 'Oups, une erreur s’est glissée. Êtes-vous sûr du numéro ?',
      });
    } else {
      this.setState({ cardError: null });
    }
  }

  submitCard = (customer, orderId) => {
    const {
      expiryMonth, expiryYear, cvv, cardNumber, cardId,
    } = this.state;
    this.setState({ error: false, errorType: undefined });
    const expiryDate = `${expiryMonth}${expiryYear}`;
    const cardData = {
      expiryDate, cvv, cardNumber, cardId,
    };
    return registerCard(cardData, customer._id)
      .then((card) => callApi('public/submitCard', 'post', { card, orderId }))
      .then(() => this.setState({ isLoading: false }))
      .then(() => navigate(routesMap.MasksSuccess.url));
  }

  submitOrder = () => {
    this.setState({ popUpWaitTimeIsOpen: true });
    const { isLoggedIn, addingNewAddress } = this.state;
    let endpoint = '';
    const request = this.createRequest();
    if (isLoggedIn && (!addingNewAddress || request.order.address)) {
      endpoint = 'createNewOrder';
    } else if (addingNewAddress) {
      endpoint = 'addAddressAndOrder';
    } else {
      endpoint = 'registerAndOrder';
    }
    callApi(endpoint, 'post', request)
      .then((res) => {
        this.setCustomerAndOrderId(res.customer, res.order.id);
        if (!this.getSelectedCardToken()) {
          this.submitCard(res.order.id);
        } else {
          navigate(routesMap.MasksSuccess.url);
        }
      })
      .catch((response) => {
        this.setState({ popUpWaitTimeIsOpen: false });
        if (typeof Raven !== 'undefined') {
          Raven.captureException(JSON.stringify(response)); // eslint-disable-line
        } else {
          // eslint-disable-next-line no-console
          console.error(response);
        }
      });
  }

  renderContactInfos = () => {
    const {
      isAlreadyCustomer, isLoggedIn,
      formInputs, formInputsAlreadyCustomer,
      selectedAddress, addingNewAddress, addressesList, isSubscribingToNewsletter,
    } = this.state;
    const { customerContext: { customer } } = this.props;

    const customerHasPhone = !!customer && !!customer.phone;
    const customerHasSubscribedToNewsletter = customer && customer.hasSubscribedToNewsletter;
    const inputList = (isAlreadyCustomer && !isLoggedIn) ? formInputsAlreadyCustomer : formInputs;
    return (
      <ContactInfos
        isAlreadyCustomer={isAlreadyCustomer}
        isLoggedIn={isLoggedIn}
        addingNewAddress={addingNewAddress}
        addressesList={addressesList}
        selectedAddress={selectedAddress}
        customerHasPhone={customerHasPhone}
        customerHasSubscribedToNewsletter={customerHasSubscribedToNewsletter}
        isSubscribingToNewsletter={isSubscribingToNewsletter}
        toggleSubscribeToNewsletter={() => this.setState({ isSubscribingToNewsletter: !isSubscribingToNewsletter })}
        formInputs={formInputs}
        inputList={inputList}
        setIsAlreadyCustomer={this.setIsAlreadyCustomer}
        login={this.login}
        onSelectAddress={this.onSelectAddress}
        addAddress={this.addAddress}
        checkFormInputValue={this.checkFormInputValue}
        setFormInputValue={this.setFormInputValue}
        setIsAlreadyCustomerWhenEmailChecked={this.setIsAlreadyCustomerWhenEmailChecked}
        showPasswordForgottenModal={() => this.setState({ isPasswordForgotten: true })}
        isMaskPage
      />
    );
  }

  renderCreditCardForm = () => {
    const { customerContext: { customer } } = this.props;
    const { selectedCardIndex, isAddingNewCard, cardError } = this.state;
    if (customer?.cards?.length > 0 && !isAddingNewCard) {
      return (
        <div>
          <CreditCardsSelector
            cards={customer.cards}
            selectedCardIndex={selectedCardIndex}
            selectCard={(index) => this.setState({ selectedCardIndex: index })}
          />
          <Button transparent mobileSmallMarginTop onClick={() => this.setState({ isAddingNewCard: true })}>
            Ajouter une carte
          </Button>
        </div>
      );
    }
    return (
      <div>
        <CreditCard
          onChange={this.setCreditCardInputValue}
          error={cardError}
        />
        <GuaranteesContainer alignItems="center" row marginBottom="0">
          <Logo marginRight={12} src={logoMangopay} alt="Logo Mangopay" />
          Règlement 100% sécurisé via Mangopay
        </GuaranteesContainer>
      </div>
    );
  }

  render() {
    const { isAlreadyCustomer, isLoggedIn, popUpWaitTimeIsOpen } = this.state;
    const { orderContext: { masks, deliveryFeeMasks } } = this.props;
    const isDone = this.checkIsDone();
    return (
      <MeetingInfosContainer>
        {popUpWaitTimeIsOpen && (<PopUpWaitTime />)}
        <Title2>Où vous livre-t-on ?</Title2>
        <ComponentsContainer>
          <div>
            {this.renderContactInfos()}
            <div>
              <Title>Votre moyen de paiement</Title>
              {this.renderCreditCardForm()}
            </div>
          </div>
          {(!isAlreadyCustomer || (isAlreadyCustomer && isLoggedIn))
            && (
              <OrderSummaryMasks
                masks={masks}
                deliveryFee={deliveryFeeMasks}
              />
            )}
        </ComponentsContainer>
        {(!isAlreadyCustomer || (isAlreadyCustomer && isLoggedIn))
          && (
            <ComponentsContainer marginBottom marginTop>
              <LogoButton onClick={() => navigate(routesMap.Masks.url)} back noZoom>Retour</LogoButton>
              {isDone
              && (
                <StyledLink onClick={this.submitOrder}>
                  <LogoButton
                    backgroundColor={colors.navy}
                    textColor={colors.white}
                    back
                    logo={tick}
                    neverHideLogo
                    mobileFirst
                  >
                    Payer
                  </LogoButton>
                </StyledLink>
              )}
              {!isDone
              && (
                <LogoButton
                  isAvailable={false}
                  backgroundColor={colors.navy}
                  textColor={colors.white}
                  back
                  logo={tick}
                  neverHideLogo
                  mobileFirst
                >
                  Payer
                </LogoButton>
              )}
            </ComponentsContainer>
          )}
      </MeetingInfosContainer>
    );
  }
}

MaskMeetingInfos.propTypes = {
  orderContext: PropTypes.shape({
    clothes: PropTypes.arrayOf(PropTypes.shape({})),
    slots: PropTypes.shape({}),
    setOrderId: PropTypes.func,
    updateDeliveryFee: PropTypes.func,
    rdv3ClicksOrder: PropTypes.shape({
      fabrics: PropTypes.arrayOf(PropTypes.string).isRequired,
      knowHow: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      suitAlterations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    promoCode: PropTypes.shape({}),
    funnelType: PropTypes.string.isRequired,
    masks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    deliveryFeeMasks: PropTypes.number.isRequired,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.shape({}),
      addresses: PropTypes.arrayOf(PropTypes.shape({})),
      cards: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
      })),
      hasSubscribedToNewsletter: PropTypes.bool,
    }),
    setCustomer: PropTypes.func,
    setCustomerDiscountVouchers: PropTypes.func,
    setSelectedAccountCategory: PropTypes.func,
  }).isRequired,
};

export default withCustomerContext(withOrderContext(MaskMeetingInfos));
